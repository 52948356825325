<!-- 积分商城 -->
<template>
    <div>
        <div class="title"></div>
        <div class="container">
            <div class="flex align-c justify-c mt-20 mb-20">
                <img :src="avatar" class="avatar">
                <div class="ml-20">
                    <p class="font-18 font-bold">{{userName}}</p>
                    <p class="font-16 mt-10"><span class="color-orange font-bold">{{points}} </span>分</p>
                </div>
            </div>
            <ul class="flex flex-wrap">
                <li class="good-item-container flex" v-for="item in goodList" :key="item.id" @click="toGoodDetail(item.id)">
                    <div class="shrink-0">
                        <el-image :src="item.thumb || 'https://o.rjkj7.com/data/upload/xcximages/xiangdon/good_default.jpg'" lazy style="width: 190px; height: 190px;">
                            <img slot="placeholder" src="https://o.rjkj7.com/data/upload/xcximages/xiangdon/good_default.jpg" style="width: 190px; height: 190px;">
                        </el-image>
                    </div>
                    <div class="flex-column justify-s-b flex-1 min-width-0 ml-10">
                        <div>
                            <p class="line-clamp2">{{item.title}}</p>
                            <p class="color-666 font-12 mt-7">规格：{{item.specTitle}}</p>
                        </div>
                        <div>
                            <p class="color-999 font-12">{{item.salesreal + item.sales}}人已领</p>
                            <p class="color-orange font-20 mt-7">￥{{item.marketprice}}+{{item.dedcredit}}积分</p>
                            <p class="color-999 font-12 mt-7 line-clamp">{{item.productionEnterprise}}</p>
                        </div>
                    </div>
                </li>
            </ul>
            <Empty v-if="goodList.length == 0" title="抱歉，没有找到符合条件的商品" />
            <el-pagination v-if="total>perPage" @current-change="pageChange" :current-page="currPage" :page-size="perPage"
                    :total="total" layout="total, prev, pager, next, jumper" background>
            </el-pagination>
        </div>
    </div>
</template>

<script>
import Empty from '@/components/empty'

export default {
    name: 'PointMall',
    components: { Empty },

    data() {
        return {
            userName: this.$store.state.userInfo.realname || this.$store.state.userInfo.nickname,
            avatar: this.$store.state.userInfo.avatar || 'https://o.rjkj7.com/data/upload/xcximages/avatar_default.png',
            points: 0,
            goodList: [],
            currPage: 1,
            perPage: 15,
            total: 0
        };
    },

    created() {
        this.getMemberIntegral()
        this.getGoods()
    },

    methods: {
        //获取积分
        getMemberIntegral() {
            this.$https.get(this.$api.getMemberIntegral,{
                mid: this.$store.state.userInfo.id
            })
            .then((res) =>{
                this.points = res.points
            })
        },

        getGoods() {
            let userInfo = this.$store.state.userInfo
            this.$https.get(this.$api.products,{
                centreId: this.$api.centreId,
                liansuoid: userInfo.upshopTid || this.$store.state.shopId || '',
                teamId: userInfo.upshopTid || this.$store.state.shopId || '',
                mid: userInfo.id || '',
                productType: 5,//0:普通 1预售 2拼团 3秒杀 4预约 5积分兑换 6砍价 10会员商品
                offset: (this.currPage - 1) * this.perPage,
                limit: this.perPage
            })
            .then((res) =>{
                this.goodList = res.data.rows
                this.total = res.data.total
                document.body.scrollIntoView()
            })
        },

        //分页
        pageChange(val) {
            if(this.currPage != val){
                this.currPage = val
                this.getGoods()
            }  
        },

        //跳转商品详情
        toGoodDetail(id) {
            let { href } = this.$router.resolve({
                                name: "goodDetail",
                                query: {
                                    id: id 
                                }
                            });
            window.open(href, '_blank')
        }
    }
}
</script>
<style lang='scss' scoped>
    .title{
        width: 100%;
        height: 230px;
        background: url(../../assets/images/point_mall_bg.png) no-repeat center;
        background-size: cover;
    }
    .avatar{
        width: 90px;
        height: 90px;
        padding: 2px;
        border: 1px solid #dedede;
        border-radius: 50%;
    }
    .good-item-container{
        width: 390px;
        padding: 9px 9px;
        margin: 0 15px 15px 0;
        border: 1px solid #DEDEDE;
        cursor: pointer;
        &:nth-child(3n){
            margin-right: 0;
        }
        &:hover{
            box-shadow: 0 0 5px #ccc;
        }
    }
</style>